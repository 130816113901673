<template>
	<a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
		<a-page-header bordered :ghost="false" title="日志详情">
			<a-descriptions size="small" :column="3">
				<a-descriptions-item label="姓名">
					{{ rowdata.author }}
				</a-descriptions-item>
				<a-descriptions-item label="日期">
					{{ rowdata.date }}
				</a-descriptions-item>
			</a-descriptions>

			<a-tabs default-active-key="1" >
				<a-tab-pane key="1" tab="内容">
					<div style="padding: 10px 0" v-html="rowdata.content"></div>
					<br />
					<!-- 附件 -->
					<a-descriptions size="small" v-if="rowdata.attachment" :column="3">
						<a-descriptions-item v-if="rowdata.attachment.length >= 1">
							<attachView :attachment="rowdata.attachment"></attachView>
						</a-descriptions-item>
					</a-descriptions>
				</a-tab-pane>
				<a-tab-pane key="2" tab="点评信息">
					<a-table :scroll="{ x: '100%',y:'300px' }" :style="{ background: '#FFF' }" size="small" :columns="columns" ref="list" :data-source="rowdata.workLogsComments" :loading="loading" :bordered="false" :pagination="false" row-key="id">
						<!--操作-->
						
					</a-table>
				</a-tab-pane>
			</a-tabs>
		</a-page-header>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';

import attachView from '@/components/common/attach-view.vue';
export default {
	components: { attachView },
	data() {
		return {
			visible: false,
			loading: false,
			data: {
				title: '',
				content: '',
				createTime: ''
			},
			rowdata: {},
			columns: [
				{ title: '点评人', dataIndex: 'staffName', width: 160, align: 'center', ellipsis: true },
				{ title: '点评内容', dataIndex: 'content', width: 160, align: 'center', ellipsis: true }
			]
		};
	},
	methods: {
		...apiUtil,
		show(row) {
			
			this.http
				.get(`/platform/work-log/details`,{id: row.id})
				.then(ret => {
					this.rowdata = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
			this.visible = true;
		},
		_close() {
			this.visible = false;
		}
	}
};
</script>
